import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.png`} alt="" />
      </Link>
      <header>
        <h2>Rashmi Gunawardana</h2>
        <p>
          <a href="mailto:nirashagunawardana9@gmail.com">nirashagunawardana9@gmail.com</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      {/* <h2>About</h2> */}
      <p>
        Hi, I&apos;m Rashmi. I am a{' '}
        final-year Information Systems undergraduate at the University
        of Colombo, a Microsoft Certified Azure AI Engineer Associate, and a Gold-level
        Microsoft Learn Student Ambassador. I specializes in cloud computing, AI, and
        data engineering, actively contributing to the tech community through mentoring,
        workshops, and research in HCI and ADHD-focused tools. I&apos;m also an award-winning
        IEEE volunteer and a passionate problem-solver driven by innovation.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? (
            <Link to="/resume" className="button">
              Resume/CV
            </Link>
          ) : (
            <Link to="/about" className="button">
              About Me
            </Link>
          )}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; Rashmi Gunawardana <Link to="/">rashmigunawardana.me</Link>.
      </p>
    </section>
  </section>
);

export default SideBar;
